import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1b4ddff5 = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _7bacc1ad = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _bb2fa860 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _36123764 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _045420ee = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _aa5e9670 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _5fa740f4 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _8e52e23e = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _55068eaa = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _59f8b76a = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _a767a642 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _3d1c54de = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _4ecf5224 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _6f7996e6 = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _983ee55e = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _d63c7810 = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _1ba3d62e = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _70a6a726 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _6e200866 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _3a7b1650 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _5e2a7f04 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _2b3b2690 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _448aeeb5 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _f530438a = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _4dc14c6c = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _4b8c7236 = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _f61f8bd8 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _2e6ddb54 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _70f25cea = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _18296022 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _7515a5e7 = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _78a531c2 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _1fb78d21 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _b1b867ba = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _f11d6704 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _0945b3c0 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _92a22004 = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _6bda1832 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _374b2232 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _17b9b384 = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _5faad2c1 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _3a7fc98c = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _0f84a48f = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _6aa39bdb = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _2d81acf6 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _09a26be7 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _60c10d6d = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _52369cb4 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _37d58c7c = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _fe06463a = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _31caf956 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _06688088 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _68665b6d = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _71394ed6 = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _acf4096e = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _1e707fbc = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _35e35e67 = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _69e3c14b = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _4644ebc4 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _71d1f60f = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _0758a547 = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _17445666 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _094d5b9d = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _62aee238 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _3562b7ca = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _8a9cf254 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _e94798dc = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _3f2449f2 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _3f1c4607 = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _e7a567ca = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _3972bac9 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _2f54da4f = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _03ea0839 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _1b4ddff5,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _7bacc1ad,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _bb2fa860,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _36123764,
    name: "calendar"
  }, {
    path: "/customer",
    component: _045420ee,
    name: "customer"
  }, {
    path: "/details",
    component: _aa5e9670,
    name: "details"
  }, {
    path: "/goals",
    component: _5fa740f4,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _8e52e23e,
    name: "maisoku"
  }, {
    path: "/media",
    component: _55068eaa,
    name: "media"
  }, {
    path: "/privacy",
    component: _59f8b76a,
    name: "privacy"
  }, {
    path: "/project",
    component: _a767a642,
    name: "project"
  }, {
    path: "/response",
    component: _3d1c54de,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _4ecf5224,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _6f7996e6,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _983ee55e,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _d63c7810,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _1ba3d62e,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _70a6a726,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _6e200866,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _3a7b1650,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _5e2a7f04,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _2b3b2690,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _448aeeb5,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _f530438a,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _4dc14c6c,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _4b8c7236,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _f61f8bd8,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _2e6ddb54,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _70f25cea,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _18296022,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _7515a5e7,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _78a531c2,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _1fb78d21,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _b1b867ba,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _f11d6704,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _0945b3c0,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _92a22004,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _6bda1832,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _374b2232,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _17b9b384,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _5faad2c1,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _3a7fc98c,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _0f84a48f,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _6aa39bdb,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _2d81acf6,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/registration",
    component: _09a26be7,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _60c10d6d,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _52369cb4,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _37d58c7c,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _fe06463a,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _31caf956,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _06688088,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _68665b6d,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _71394ed6,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _acf4096e,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _1e707fbc,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _35e35e67,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _69e3c14b,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _4644ebc4,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _71d1f60f,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _0758a547,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _17445666,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _094d5b9d,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _62aee238,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _3562b7ca,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _8a9cf254,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _e94798dc,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _3f2449f2,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _3f1c4607,
    name: "property-property",
    children: [{
      path: "contact",
      component: _e7a567ca,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _3972bac9,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _2f54da4f,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _03ea0839,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
